<template>
  <ws-dialog
      v-model="display"
      :title="$t('Preview')"
      width="800"
      no-navigation
  >

    <v-sheet v-if="loading" class="d-flex align-center justify-center" height="300">
      <div style="width: 100px">
        <v-progress-linear rounded indeterminate :color="wsACCENT" />
      </div>
    </v-sheet>
    <div v-else>

      <h4 v-if="!studentTestId" class="font-weight-regular wsACCENT mb-5">
        {{ $t('QuestionsWillBeMixedForStudent')  }}
      </h4>

      <div v-else-if="!!studentTestId" class="d-flex justify-space-between mb-5">
        <v-sheet width="100%" :style="`border : 1px solid ${wsBACKGROUND}`" class="d-flex align-center justify-space-between wsRoundedSemi mr-3 pa-3">
          <div>
            <h4 class="wsDARKER"> {{  timeSpent }}</h4>
            <h5 class="wsACCENT">{{  MONTH_DAY_TIME(entity.date_started ) }}</h5>
          </div>
          <v-icon :color="wsACCENT" large>mdi-calendar</v-icon>
        </v-sheet>
        <v-sheet width="100%" :style="`border : 1px solid ${wsBACKGROUND}`" class="d-flex align-center justify-space-between wsRoundedSemi mr-3 pa-3">

          <div>
            <h2 class="wsDARKER"> {{  entity.result  }}</h2>
            <h5 class="wsACCENT">{{ $t('Result') }}</h5>
          </div>
          <v-icon :color="wsACCENT" large>mdi-flag</v-icon>

        </v-sheet>
        <v-sheet width="100%" :style="`border : 1px solid ${wsBACKGROUND}`" class="d-flex align-center justify-space-between wsRoundedSemi mr-3 pa-3">

          <div>
            <h2 class="wsDARKER"> {{ correct }}</h2>
            <h5 class="wsACCENT">{{ $t('CorrectAnswers') }}</h5>
          </div>
          <v-icon :color="wsSUCCESS" large>mdi-check-circle</v-icon>


        </v-sheet>
        <v-sheet width="100%" :style="`border : 1px solid ${wsBACKGROUND}`" class="d-flex align-center justify-space-between wsRoundedSemi  pa-3">

          <div>
            <h2 class="wsDARKER"> {{ wrong }}</h2>
            <h5 class="wsACCENT">{{ $t('WrongAnswers') }}</h5>
          </div>
          <v-icon :color="wsWARNING" large>mdi-close-circle</v-icon>

        </v-sheet>
      </div>



      <question-viewer
          v-for="(question,i) in entity.questions" :key="i"
          :question="question"
          :order-number="i + 1"
          :course-id="question.course_id"
          :test-id="question.uuid"
          :preview="!!testId"
          :review="!!studentTestId"
          bordered
      />
    </div>



  </ws-dialog>
</template>

<script>
import {mapActions} from "vuex";
import questionViewer from "@/components/pages/westudy/tests/UI/questionViewer";
import tests from "@/mixins/tests";

export default {
  name: "testPreviewDialog",
  mixins : [tests],
  components : {
    questionViewer
  },
  props : {
    value : {
      type : Boolean
    },
    testId : {
      type : String
    },
    studentTestId : {
      type : String
    }
  },
  data() {
    return {
      loading : false,
      display : false,
      entity : {
        questions : []
      },
      correct : 0,
      wrong : 0
    }
  },
  computed: {
    timeSpent() {
      let time = this.entity.time_spent || 0
      let minutes = Math.floor(time/60)
      let seconds = time%60
      minutes = minutes < 10 ? `0${minutes}` : minutes
      seconds = seconds < 10 ? `0${seconds}` : seconds
      return `${minutes}:${seconds}`
    }
  },
  watch : {
    display() {
      if ( this.value !== this.display ) {
        this.$emit('input' , this.display)
      }
    },
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    }
  },
  methods : {
    ...mapActions('tests' , [
        'PREVIEW_TEST' ,
        'GET_STUDENT_TEST_ADMIN',
    ]),

    getStatistic() {
      this.entity.questions.forEach(question => {
        if ( this.QUESTION_HAS_CORRECT_REPLY(question)) {
          this.correct++
        } else {
          this.wrong++
        }
      })
    },

    async initPage() {

      this.loading = true
      let result = this.testId ? await this.PREVIEW_TEST(this.testId) : await this.GET_STUDENT_TEST_ADMIN(this.studentTestId)
      this.loading = false
      if ( !result ) {
        return this.ERROR()
      }
      this.entity = result
      this.getStatistic()
    },
  },
  mounted() {
    if ( this.value ) {
      this.display = this.value
    }
    if ( this.testId || this.studentTestId ) {
      this.initPage()
    }

  }
}
</script>

<style scoped>

</style>